









































import Vue from "vue";
import { initialFiltersConfig } from "@/options/filterOptions";
import { fabric } from "fabric";
import { ImageFilter } from "@/store/types";
import { applyImageFilter } from "@/helpers/filterHelpers";
import { mapState } from "vuex";
fabric.textureSize = 5000;
const types = ["contrast", "saturation", "brightness", "blur", "rotation"];

export default Vue.extend({
  name: "ImageFilterForm",
  props: {
    selectedImage: {
      type: [Object],
      required: false
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      filterModal: false,
      filterName: "",
      filterType: "default",
      initialFiltersConfig,
      saturation: false,
      contrast: false,
      brightness: false,
      blur: false,
      rotation: false, // hue rotation
      filtersOptions: {
        brightness: 0,
        contrast: 0,
        saturation: 0,
        blur: 0,
        rotation: 0
      },
      filtersToCreate: {
        brightness: 0,
        contrast: 0,
        saturation: 0,
        blur: 0,
        rotation: 0
      }
    };
  },
  computed: {
    ...mapState("users", ["userSettings"]),
    userFilters: function(): Array<ImageFilter> {
      if (this.userSettings && "imageFilters" in this.userSettings) {
        return this.userSettings.imageFilters;
      }

      return [];
    }
  },
  watch: {
    selectedImage: function() {
      if ("filterKey" in this.selectedImage) {
        this.filterType = this.selectedImage.filterKey;
      } else {
        this.filterType = "default";
      }
    },
    visible: function() {
      if (this.visible) {
        const filter = initialFiltersConfig.find(
          el => el.key === this.filterType
        ) as ImageFilter;
        this.changeImageFilter(filter);
      }
    }
  },
  methods: {
    async deleteFilter(filter: ImageFilter) {
      if (await this.$confirm("Are you sure?")) {
        const item = this.userSettings.imageFilters.find(
          (elem: ImageFilter) => elem.key === filter.key
        );
        if (item) {
          const nextFilters = this.userSettings.imageFilters.filter(
            (elem: ImageFilter) => elem.key !== filter.key
          );
          await this.$store.dispatch("users/updateUserSettings", {
            imageFilters: nextFilters
          });
        }
      }
    },
    changeImageFilter(filter: ImageFilter) {
      const subFilter = applyImageFilter(filter);
      this.$emit("changeImageFilter", subFilter);
    }
  }
});
